import React, { FC, useEffect } from 'react';
import { translations } from '../../../../../locales/i18n';
import { CountdownItem } from '../CountdownItem/CountdownItem';
import { t } from 'i18next';
import styles from './CountdownRenderer.module.css';

const { counter } = translations;

type CountdownRendererProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  completed: boolean;
  onCountdownEnd?: () => void;
};

export const CountdownRenderer: FC<CountdownRendererProps> = ({
  days,
  hours,
  minutes,
  seconds,
  completed,
  onCountdownEnd,
}) => {
  useEffect(() => {
    if (completed && onCountdownEnd) {
      onCountdownEnd();
    }
  }, [completed, onCountdownEnd]);

  if (completed) {
    return <span>{t(counter.eventEnded)}</span>;
  } else {
    return (
      <div className={styles.countdown}>
        <CountdownItem label={t(counter.days)} value={days} />
        <CountdownItem label={t(counter.hours)} value={hours} />
        <CountdownItem label={t(counter.minutes)} value={minutes} />
        <CountdownItem label={t(counter.seconds)} value={seconds} />
      </div>
    );
  }
};
