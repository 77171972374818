import { useEffect } from 'react';
import { useGetPoints } from '../../../../hooks/useGetPoints';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../reducer';
import { addPoints } from '../slice';

export const PointItem = ({ transaction }) => {
  const dispatch = useDispatch();
  const { token, timestamp, amount } = transaction;
  const { points } = useGetPoints(token, timestamp, amount.toString());
  const timestamps = useSelector(
    (state: RootState) => state.pointsData.timestamps,
  );

  useEffect(() => {
    if (points && !timestamps.includes(timestamp)) {
      dispatch(addPoints({ points: Number(points), timestamp }));
    }
  }, [points, timestamp, timestamps, dispatch]);

  return null;
};
