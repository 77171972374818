import { providers } from 'ethers';
import resolveConfig from 'tailwindcss/resolveConfig';

import { EIP1193Provider } from '@sovryn/onboard-common';
import tailwindConfig from '@sovryn/tailwindcss-config';

import { Environments } from '../types/global';
import { START_BLOCK_MAINNET, START_BLOCK_TESTNET } from '../constants/general';
import { SupportedEthTokensData } from '../contracts/tokenDetails';

export const currentNetwork: Environments = !!process.env.REACT_APP_NETWORK
  ? (process.env.REACT_APP_NETWORK as Environments)
  : Environments.Mainnet;

export const isMainnet = () =>
  process.env.REACT_APP_NETWORK === Environments.Mainnet;

export const signMessage = async (
  provider: EIP1193Provider,
  message: string,
) => {
  const signer = new providers.Web3Provider(provider);
  const signature = await signer.getSigner().signMessage(message);

  return signature;
};

export const isMobileDevice = () => {
  const config = resolveConfig(tailwindConfig);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const widthToCheck: string = config?.theme?.screens.md;
  const screenWidth = window?.visualViewport?.width || 0;
  return screenWidth < parseInt(widthToCheck || '0');
};

export const sleep = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms));

export const isStaging = () => process.env.REACT_APP_STAGING === 'true';

export const areAddressesEqual = (address1: string, address2: string) =>
  address1.toLowerCase() === address2.toLowerCase();

export const removeTrailingZerosFromString = (value: string) =>
  value.includes('.') ? value.replace(/\.?0+$/, '') : value;

export const depositStartBlockNumber = isMainnet()
  ? START_BLOCK_MAINNET
  : START_BLOCK_TESTNET;

export const getCovalentApiKey = () => process.env.REACT_APP_COVALENT_API_KEY;

export const getDecimalPrecision = (symbol: string) => {
  const tokenData = SupportedEthTokensData.find(
    token => token.symbol === symbol,
  );
  return tokenData?.decimalPrecision;
};
export const bobBackendURL = isMainnet()
  ? 'https://fusion-api.gobob.xyz'
  : 'https://fusion-api-sepolia.gobob.xyz';
