export const APPROVAL_FUNCTION = 'approve';

export const DEFAULT_TIMEOUT_SECONDS = 5000;

export const PAGE_LIMIT = 50000;

export const MS = 1e3;

export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_YEAR = 31536000;

export const TAB_ACTIVE_CLASSNAME = 'bg-gray-70 text-primary-20';

export const EXTRA_SPICE_POINTS_MULTIPLIER = 0.5;
export const RUNES_POINTS_MULTIPLIER = 3.14;

export const START_BLOCK_TESTNET = 5477860;
export const START_BLOCK_MAINNET = 19526394;
export const BLOCK_INCREMENT = 50000;
export const TOPIC_HASH =
  '0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef'; // Transfer(address,address,unit256)

export const DEPOSIT_OPEN_DATE = '2024-03-27T15:00:00Z';
export const DEPOSIT_CLOSE_DATE = '2024-04-30T23:30:00Z';

export const SPICE_POINTS_END_DATE = '2024-05-01T23:59:59Z';
export const EXTRA_SPICE_POINTS_END_DATE = '2024-05-31T12:00:00Z';
export const RUNES_POINTS_END_DATE = '2024-06-30T12:00:00Z';

export const PREDEPOSITED_FUNDS_USD = 7_367_970;
