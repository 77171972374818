export const contracts = {
  eth: {
    lockDrop: {
      address: '0x61dc14b28d4dbcd6cf887e9b72018b9da1ce6ff7',
      getAbi: async () => (await import('./abis/LockDrop.json')).default,
    },
    gnosisSafeMultisig: {
      address: '0x949Cf9295d2950B6bD9B7334846101E9aE44BBB0',
      getAbi: async () => (await import('./abis/erc20.json')).default,
    },
    safeModuleContract: {
      address: '0x5ddC7eB2958C07c1F02f0A834FbA8982487d1940',
      getAbi: async () =>
        (await import('./abis/safeModuleContract.json')).default,
    },
  },
  ethTestnet: {
    lockDrop: {
      address: '0x007b3aa69a846cb1f76b60b3088230a52d2a83ac',
      getAbi: async () => (await import('./abis/LockDrop.json')).default,
    },
    gnosisSafeMultisig: {
      address: '0x064A379c959dB1Ed1318114E2363a6c8d103ADd3',
      getAbi: async () => (await import('./abis/erc20.json')).default,
    },
    safeModuleContract: {
      address: '0xA5B869A1eA1428e76916f6cBbdAD543FDD51E47d',
      getAbi: async () =>
        (await import('./abis/safeModuleContract.json')).default,
    },
  },
} as const;
