import { fromWei } from './../../../../utils/math';
import {
  TransactionDeposit,
  TransactionDepositData,
} from '../../../pages/LiquidityPage/types';
import { decimalic } from '../../../../utils/math';
import { SupportedEthTokens } from '../../../../types/types';
import { ETH_TOKENS } from '../../../../contracts/tokens/eth';
import { currentNetwork, getDecimalPrecision } from '../../../../utils/helpers';
import dayjs from 'dayjs';

export const useGetPointsFilteredTransactions = (
  transactions: TransactionDepositData[],
  safeContractAddress: string | undefined,
  account: string,
): TransactionDeposit[] => {
  const filteredTransactions: TransactionDeposit[] = [];

  transactions.forEach(tx => {
    const isNativeToken = tx.decoded.name === 'SafeReceived';
    const isUserTransaction = tx.decoded.params.some(
      param => param.value.toLowerCase() === account.toLowerCase(),
    );

    if (
      (isNativeToken &&
        tx.sender_address.toLowerCase() !==
          safeContractAddress?.toLowerCase()) ||
      (!isNativeToken &&
        tx.decoded.params[1].value.toLowerCase() !==
          safeContractAddress?.toLowerCase()) ||
      !isUserTransaction
    ) {
      return;
    }

    const token = isNativeToken
      ? SupportedEthTokens.eth
      : tx.sender_contract_ticker_symbol.toLowerCase();
    const decimalPrecision = getDecimalPrecision(token);
    const tokenAddress = isNativeToken
      ? ETH_TOKENS[SupportedEthTokens.eth][currentNetwork]
      : tx.sender_address;
    const timestamp = dayjs(tx.block_signed_at).unix();
    const amount = isNativeToken
      ? fromWei(tx.decoded.params[1].value, decimalPrecision)
      : fromWei(tx.decoded.params[2].value, decimalPrecision);

    if (SupportedEthTokens[token.toLowerCase()]) {
      const newDeposit: TransactionDeposit = {
        amount: decimalic(amount),
        token,
        usdRate: decimalic(1),
        tokenAddress,
        timestamp: timestamp,
      };
      filteredTransactions.push(newDeposit);
    }
  });

  return filteredTransactions;
};
