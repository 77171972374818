import { useCallback, useMemo } from 'react';
import { Contract } from 'ethers';
import { t } from 'i18next';

import { useAccount } from '../../../../hooks/useAccount';
import { useTransactionContext } from '../../../../contexts/TransactionContext';
import {
  Transaction,
  TransactionType,
} from '../../../organisms/TransactionStepDialog/TransactionStepDialog.types';
import { translations } from '../../../../locales/i18n';
import { getTokenDisplayName } from '../../../../constants/tokens';
import { SupportedEthTokens } from '../../../../types/types';
import { Decimal } from '@sovryn/utils';
import { GAS_LIMIT } from '../../../../constants/gasLimits';
import ERC20_ABI from '../../../../contracts/abis/erc20.json';
import { ETH_TOKENS } from '../../../../contracts/tokens/eth';
import { currentNetwork } from '../../../../utils/helpers';
import { useLoadContract } from '../../../../hooks/useLoadContract';
import { SupportedEthTokensData } from '../../../../contracts/tokenDetails';

export const useHandleDeposit = (
  amount: Decimal,
  token: SupportedEthTokens,
  onComplete: () => void,
) => {
  const { signer, account } = useAccount();
  const { setTransactions, setIsOpen, setTitle } = useTransactionContext();

  const tokenAddress = ETH_TOKENS[token.toLocaleLowerCase()][currentNetwork];

  const contract = useMemo(
    () => new Contract(tokenAddress.toLowerCase(), ERC20_ABI, signer),
    [tokenAddress, signer],
  );

  const gnosisSafeContractAddress =
    useLoadContract('gnosisSafeMultisig')?.address;

  const isNativeToken = useMemo(
    () => token === SupportedEthTokens.eth,
    [token],
  );

  const sendEther = useCallback(
    async (to, value) => {
      if (!signer) {
        throw new Error('Signer not available.');
      }
      try {
        const transaction = await signer.sendTransaction({
          to: to,
          value: value,
        });
        await transaction.wait();
        onComplete();
      } catch (error) {
        console.error('Error sending ETH:', error);
      }
    },
    [signer, onComplete],
  );

  const deposit = useCallback(async () => {
    try {
      if (!contract) {
        throw new Error('Contract not available.');
      }
      const supportedToken = SupportedEthTokensData.find(
        tokenData => tokenData.symbol === token,
      );
      const weiAmount = amount
        .mul(10 ** (supportedToken?.decimalPrecision || 18))
        .toString();
      const transactions: Transaction[] = [];

      if (!isNativeToken) {
        const gasLimit = GAS_LIMIT.DEPOSIT;
        const approveTx = await contract.approve(
          account.toLowerCase(),
          weiAmount,
          { gasLimit: gasLimit },
        );
        await approveTx.wait();
      }

      if (!isNativeToken && gnosisSafeContractAddress) {
        transactions.push({
          title: t(translations.liquidityPage.txDialog.deposit, {
            asset: getTokenDisplayName(token),
          }),
          request: {
            type: TransactionType.signTransaction,
            contract: contract,
            fnName: 'transferFrom',
            args: [account.toLowerCase(), gnosisSafeContractAddress, weiAmount],
            gasLimit: GAS_LIMIT.DEPOSIT,
          },
          token: token.toLowerCase(),
        });

        setTransactions(transactions);
        setTitle(
          t(translations.liquidityPage.txDialog.depositTitle, {
            asset: getTokenDisplayName(token),
          }),
        );
        setIsOpen(true);
      } else {
        if (gnosisSafeContractAddress) {
          await sendEther(gnosisSafeContractAddress.toLowerCase(), weiAmount);
        }
      }
    } catch (error) {
      console.error('Error during deposit:', error);
    }
  }, [
    contract,
    account,
    amount,
    token,
    isNativeToken,
    gnosisSafeContractAddress,
    sendEther,
    setTransactions,
    setTitle,
    setIsOpen,
  ]);

  return deposit;
};
