import { useEffect, useMemo, useState } from 'react';
import { getCovalentApiKey, isMainnet } from '../utils/helpers';
import { useLoadContract } from './useLoadContract';
import {
  PAGE_LIMIT,
  START_BLOCK_MAINNET,
  START_BLOCK_TESTNET,
} from '../constants/general';
import { TransactionDepositData } from '../app/pages/LiquidityPage/types';

export const useGetAllEthDeposits = () => {
  const [pastAllEthDeposits, setAllEthDeposits] = useState<
    TransactionDepositData[]
  >([]);
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(0);
  const gnosisSafeContractAddress =
    useLoadContract('gnosisSafeMultisig')?.address;

  const getChainName = useMemo(
    () => (isMainnet() ? 'eth-mainnet' : 'eth-sepolia'),
    [],
  );

  const startBlock = useMemo(
    () => (isMainnet() ? START_BLOCK_MAINNET : START_BLOCK_TESTNET),
    [],
  );

  useEffect(() => {
    const fetchEthData = async () => {
      if (!gnosisSafeContractAddress || !getChainName) {
        return;
      }
      setLoading(true);

      let allDeposits: TransactionDepositData[] = [];
      let pageNumber = page;
      let hasMore = true;

      try {
        while (hasMore) {
          const response = await fetch(
            `https://api.covalenthq.com/v1/${getChainName}/events/address/${gnosisSafeContractAddress}/?starting-block=${startBlock}&ending-block=latest&page-size=${PAGE_LIMIT}&page-number=${pageNumber}&key=${getCovalentApiKey()}`,
          );
          const responseData = await response.json();

          const transactions = responseData.data.items;

          allDeposits = [...allDeposits, ...transactions];

          if (!responseData.data.pagination.has_more) {
            hasMore = false;
            break;
          }

          pageNumber++;
          setPage(pageNumber);
        }

        allDeposits = allDeposits.filter(deposit =>
          deposit.decoded.name.includes('SafeReceived'),
        );
        setAllEthDeposits(allDeposits);
      } catch (error) {
        console.error('Error fetching deposits:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchEthData();
  }, [gnosisSafeContractAddress, getChainName, startBlock, page]);

  return { pastAllEthDeposits, loading };
};
