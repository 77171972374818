import { SupportedEthTokens } from '../types/types';

export const SupportedEthTokensList = {
  wbtc: 'WBTC',
  tbtc: 'TBTC',
  eth: 'ETH',
  reth: 'RETH',
  wsteth: 'WSTETH',
  usdt: 'USDT',
  usdc: 'USDC',
  dai: 'DAI',
};

export const tokensDisplayName = {
  [SupportedEthTokensList.wbtc]: 'WBTC',
  [SupportedEthTokensList.tbtc]: 'TBTC',
  [SupportedEthTokensList.eth]: 'ETH',
  [SupportedEthTokensList.reth]: 'RETH',
  [SupportedEthTokensList.wsteth]: 'WSTETH',
  [SupportedEthTokensList.usdt]: 'USDT',
  [SupportedEthTokensList.usdc]: 'USDC',
  [SupportedEthTokensList.dai]: 'DAI',
};

export const resolveTokenName = (token: SupportedEthTokens | string): string =>
  token;

export const getTokenDisplayName = (
  token: SupportedEthTokens | string,
): string => {
  token = resolveTokenName(token);
  return tokensDisplayName[token?.toLowerCase()] || token?.toUpperCase();
};
