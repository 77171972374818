import { SupportedEthTokens } from './../types/types';
import { useEffect, useMemo, useState } from 'react';

import { BigNumber, constants, Contract } from 'ethers';
import { Subscription } from 'zen-observable-ts';
import erc20Abi from '../contracts/abis/erc20.json';
import { ChainId, getProvider } from '@sovryn/ethers-provider';
import { Decimal } from '@sovryn/utils';

import {
  CacheCallOptions,
  idHash,
  observeCall,
  startCall,
} from '../store/rxjs/provider-cache';
import { fromWei, decimalic } from '../utils/math';
import { useBlockNumber } from './useBlockNumber';
import { useIsMounted } from './useIsMounted';
import { useWalletConnect } from './useWalletConnect';
import { getEthChainId } from '../utils/chain';
import { SupportedEthTokensData } from '../contracts/tokenDetails';
import { currentNetwork } from '../utils/helpers';
import { ETH_TOKENS } from '../contracts/tokens/eth';

export type AssetBalanceResponse = {
  balance: Decimal;
  weiBalance: string;
  bigNumberBalance: BigNumber;
  decimalPrecision?: number;
  loading: boolean;
  error: Error | null;
  hashedArgs?: string;
};

export const useAssetBalance = (
  asset: SupportedEthTokens,
  chainId: ChainId = getEthChainId(),
  address: string | null = null,
  walletIndex: number = 0,
  options?: Partial<CacheCallOptions>,
): AssetBalanceResponse => {
  const { value: block } = useBlockNumber(chainId);
  const { wallets } = useWalletConnect();
  const isMounted = useIsMounted();

  const account = useMemo(
    () =>
      address === null ? wallets[walletIndex]?.accounts[0]?.address : address,
    [address, walletIndex, wallets],
  );
  const tokenAddress = useMemo(
    () => ETH_TOKENS[asset.toLocaleLowerCase()][currentNetwork],
    [asset],
  );

  const [state, setState] = useState<AssetBalanceResponse>({
    balance: Decimal.ZERO,
    weiBalance: '0',
    bigNumberBalance: BigNumber.from(0),
    loading: false,
    error: null,
    hashedArgs: '',
  });

  useEffect(() => {
    if (!isMounted() || !account) {
      return;
    }

    let sub: Subscription;

    const runAsync = async () => {
      const hashedArgs = idHash([
        tokenAddress,
        tokenAddress === constants.AddressZero ? 'nativeBalance' : 'balanceOf',
        account,
      ]);

      if (hashedArgs === state.hashedArgs || state.loading) {
        return;
      }

      setState(prevState => ({
        ...prevState,
        hashedArgs,
        loading: true,
      }));

      sub = observeCall(hashedArgs).subscribe(e => {
        const supportedToken = SupportedEthTokensData.find(
          tokenData => tokenData.symbol === asset,
        );
        const decimal = decimalic(
          fromWei(
            e.result.value === null ? 0 : e.result.value,
            supportedToken?.decimalPrecision || 18,
          ),
        );
        const bn = decimal.toBigNumber();
        setState({
          ...e.result,
          weiBalance: bn.toString(),
          bigNumberBalance: bn,
          balance: decimal,
          decimalPrecision: supportedToken?.decimalPrecision || 18,
          loading: false,
        });
      });

      const callback =
        tokenAddress === constants.AddressZero
          ? () =>
              getProvider(chainId)
                .getBalance(account)
                .then(result => result.toString())
          : () =>
              new Contract(tokenAddress, erc20Abi, getProvider(chainId))
                .balanceOf(account)
                .then(result => result.toString());

      startCall(hashedArgs, callback, {
        ...options,
        blockNumber: options?.blockNumber || block,
      });
    };

    runAsync().catch(e =>
      setState(prev => ({
        ...prev,
        weiBalance: '0',
        balance: Decimal.ZERO,
        bigNumberBalance: BigNumber.from(0),
        loading: false,
        error: e,
        hashedArgs: '',
      })),
    );

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
  }, [
    account,
    asset,
    chainId,
    isMounted,
    tokenAddress,
    options,
    block,
    state.hashedArgs,
    state.loading,
  ]);

  return useMemo(
    () => ({
      ...state,
      weiBalance: state.weiBalance === null ? '0' : state.weiBalance,
      bigNumberBalance:
        state.weiBalance === null ? BigNumber.from(0) : state.bigNumberBalance,
    }),
    [state],
  );
};
