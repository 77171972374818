import { useEffect, useMemo, useState } from 'react';
import { ContractInterface, ethers } from 'ethers';
import { ChainId, getProvider } from '@sovryn/ethers-provider';
import { DEFAULT_CHAIN_ID } from '../config/chains';
import { useAccount } from './useAccount';
import { useIsMounted } from './useIsMounted';
import { networkMap } from '../utils/chain';
import { contracts } from '../contracts';

export const useLoadContract = (
  contractName: string,
  chain: ChainId = DEFAULT_CHAIN_ID,
  customSigner?: ethers.providers.JsonRpcSigner,
) => {
  const isMounted = useIsMounted();
  const { signer: userSigner } = useAccount();
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [abi, setAbi] = useState<ContractInterface | undefined>(undefined);
  const provider = useMemo(() => getProvider(chain), [chain]);

  const network = useMemo(() => networkMap[chain], [chain]);

  useEffect(() => {
    const loadContractData = async () => {
      const contractData = contracts[network][contractName];

      if (contractData) {
        setAddress(contractData.address);
        const abiData = await contractData.getAbi();
        if (isMounted()) {
          setAbi(abiData);
        }
      }
    };
    loadContractData();
  }, [contractName, isMounted, network]);

  return useMemo(() => {
    if (address && abi) {
      const signer = customSigner || userSigner || provider;
      return new ethers.Contract(address, abi, signer);
    }
  }, [address, abi, customSigner, userSigner, provider]);
};
