import React, { FC, useState } from 'react';
import { useEffect } from 'react';

import classNames from 'classnames';

import { getTokenDisplayName } from '../../../constants/tokens';
import styles from './AssetRenderer.module.css';
import { SupportedEthTokens } from '../../../types/types';
import { SupportedEthTokensData } from '../../../contracts/tokenDetails';

type AssetRendererProps = {
  /**
   * The asset that will be rendered and it's required.
   */
  asset?: SupportedEthTokens;
  /**
   * The asset address.
   */
  address?: string;
  /**
   * Whether to show the asset logo or not.
   * @default false
   * */
  showAssetLogo?: boolean;
  /**
   * Applied classNames to the asset element.
   * */
  assetClassName?: string;
  /**
   * Applied classNames to the outer element.
   * */
  className?: string;
  /**
   * Applied classNames to the asset logo element.
   * */
  logoClassName?: string;
};

export const AssetRenderer: FC<AssetRendererProps> = ({
  asset,
  address,
  showAssetLogo,
  assetClassName,
  className,
  logoClassName,
}) => {
  const [token, setToken] = useState(asset);
  const [logo, setLogo] = useState<string | undefined>(undefined);

  useEffect(() => {
    const getAssetDetails = async () => {
      if (asset) {
        const assetDetails = Object.values(SupportedEthTokensData).find(
          item => item.symbol === asset,
        );
        const logo = await assetDetails?.getIcon();
        setLogo(logo);
        setToken(asset);
      }
    };

    !address && !!asset && getAssetDetails();
  }, [address, asset, showAssetLogo]);

  return (
    <div className={classNames(styles.container, className)}>
      {showAssetLogo && logo && (
        <div
          className={classNames(styles.assetLogo, logoClassName)}
          dangerouslySetInnerHTML={{ __html: logo }}
        />
      )}
      {token && (
        <span className={classNames(styles.asset, assetClassName)}>
          {getTokenDisplayName(token)}
        </span>
      )}
    </div>
  );
};
