import { combineReducers } from '@reduxjs/toolkit';
import transactionsReducer from '../app/pages/LiquidityPage/slice';
import pointsReducer from '../app/pages/RewardsPage/slice';

const rootReducer = combineReducers({
  transactionsData: transactionsReducer,
  pointsData: pointsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
