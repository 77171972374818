export enum SupportedEthTokens {
  wbtc = 'wbtc',
  tbtc = 'tbtc',
  eth = 'eth',
  reth = 'reth',
  wsteth = 'wsteth',
  usdt = 'usdt',
  usdc = 'usdc',
  dai = 'dai',
}
