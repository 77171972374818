import React, { FC, useState, useEffect, useMemo } from 'react';
import { t } from 'i18next';
import { Button, ButtonStyle, Paragraph } from '@sovryn/ui';
import Countdown from 'react-countdown';
import dayjs from 'dayjs';
import { translations } from '../../../locales/i18n';
import {
  DEPOSIT_OPEN_DATE,
  DEPOSIT_CLOSE_DATE,
} from '../../../constants/general';
import { CountdownRenderer } from './components/CountdownRenderer/CountdownRenderer';
import styles from './Counter.module.css';
import { useNavigate } from 'react-router-dom';

const TARGET_DATE_KEY = 'targetDate';
const { counter } = translations;

export const Counter: FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const navigate = useNavigate();
  const isDepositOpen = useMemo(
    () => dayjs().isAfter(dayjs(DEPOSIT_OPEN_DATE)),
    [],
  );
  const isDepositClose = useMemo(
    () => dayjs().isBefore(dayjs(DEPOSIT_CLOSE_DATE)),
    [],
  );
  const isDepositOver = useMemo(
    () => dayjs().isAfter(dayjs(DEPOSIT_CLOSE_DATE)),
    [],
  );

  useEffect(() => {
    const targetDate = localStorage.getItem(TARGET_DATE_KEY);
    if (targetDate) {
      setStartDate(new Date(targetDate));
    }
  }, []);

  useEffect(() => {
    let targetDate: Date | null = null;
    if (isDepositOpen) {
      targetDate = dayjs(DEPOSIT_CLOSE_DATE).toDate();
    } else if (isDepositClose) {
      targetDate = dayjs(DEPOSIT_OPEN_DATE).toDate();
    }
    if (targetDate) {
      localStorage.setItem(TARGET_DATE_KEY, targetDate.toISOString());
      setStartDate(targetDate);
    }
  }, [isDepositOpen, isDepositClose]);

  return (
    <div className={styles.wrapper}>
      <div
        className={isDepositOver ? styles.depositsOver : styles.depositsOpen}
      >
        {isDepositOver ? (
          <>
            <Paragraph
              children={t(counter.depositsOver)}
              className="lg:text-5xl text-3xl leading-10 font-druk font-medium text-sov-white uppercase lg:pt-[0.3rem]"
            />
            <Button
              style={ButtonStyle.primary}
              text={t(translations.common.buttons.checkPoints)}
              className="lg:ml-10"
              onClick={() => navigate('/rewards')}
            />
          </>
        ) : (
          <>
            <Paragraph
              children={t(counter.comingSoon)}
              className="text-xs font-medium lg:text-sov-white text-gray-40 lg:mb-2"
            />
            <Paragraph
              children={
                isDepositOpen
                  ? t(counter.depositClosingIn)
                  : t(counter.depositOpensIn)
              }
              className="lg:text-5xl text-3xl leading-10 font-druk font-medium text-sov-white uppercase lg:pt-[0.3rem]"
            />
          </>
        )}
      </div>
      {startDate && !isDepositOver && (
        <Countdown
          date={startDate}
          renderer={props => <CountdownRenderer {...props} />}
        />
      )}
    </div>
  );
};
