import { SupportedEthTokens } from '../../types/types';

export const SupportedEthTokensData = [
  {
    symbol: SupportedEthTokens.wbtc,
    decimalPrecision: 8,
    getIcon: async () => (await import('../tokenDetails/logos/wbtc')).default,
    multiplier: 1.5,
  },
  {
    symbol: SupportedEthTokens.tbtc,
    decimalPrecision: 18,
    getIcon: async () => (await import('../tokenDetails/logos/tbtc')).default,
    multiplier: 1.5,
  },
  {
    symbol: SupportedEthTokens.eth,
    decimalPrecision: 18,
    getIcon: async () => (await import('../tokenDetails/logos/eth')).default,
    multiplier: 1,
  },
  {
    symbol: SupportedEthTokens.reth,
    decimalPrecision: 18,
    getIcon: async () => (await import('../tokenDetails/logos/reth')).default,
    multiplier: 1.3,
  },
  {
    symbol: SupportedEthTokens.wsteth,
    decimalPrecision: 18,
    getIcon: async () => (await import('../tokenDetails/logos/wsteth')).default,
    multiplier: 1.3,
  },
  {
    symbol: SupportedEthTokens.usdt,
    decimalPrecision: 6,
    getIcon: async () => (await import('../tokenDetails/logos/usdt')).default,
    multiplier: 1.3,
  },
  {
    symbol: SupportedEthTokens.usdc,
    decimalPrecision: 6,
    getIcon: async () => (await import('../tokenDetails/logos/usdc')).default,
    multiplier: 1.3,
  },
  {
    symbol: SupportedEthTokens.dai,
    decimalPrecision: 18,
    getIcon: async () => (await import('../tokenDetails/logos/dai')).default,
    multiplier: 1.3,
  },
];
