import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PointsState } from './types';

const initialState: PointsState = {
  points: 0,
  timestamps: [],
};

const pointsSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {
    addPoints(
      state,
      action: PayloadAction<{ points: number; timestamp: number }>,
    ) {
      state.points += action.payload.points;
      state.timestamps.push(action.payload.timestamp);
    },
    clearPoints(state) {
      state.points = 0;
      state.timestamps = [];
    },
  },
});

export const { addPoints, clearPoints } = pointsSlice.actions;

export default pointsSlice.reducer;
