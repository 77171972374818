import React, { FC } from 'react';

export const CountdownItem: FC<{ label: string; value: number }> = ({
  label,
  value,
}) => (
  <div className="text-center">
    <span>{label}</span>
    <div>{value}</div>
  </div>
);
