import { ChainIds } from '@sovryn/ethers-provider';
import { ChainId } from '@sovryn/ethers-provider';

import { isMainnet } from './helpers';
import { ETH_CHAIN_ID } from '../config/chains';

export const getEthChainId = () =>
  isMainnet() ? ETH_CHAIN_ID : ChainIds.SEPOLIA;

export const isEthChain = (chainId: ChainId) =>
  [ETH_CHAIN_ID, ChainIds.SEPOLIA].includes(chainId as ChainIds);

export const networkMap = {
  [ETH_CHAIN_ID]: 'eth',
  [ChainIds.SEPOLIA]: 'ethTestnet',
} as const;
