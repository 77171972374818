import React, { FC, useMemo, useReducer } from 'react';
import { Accordion, Paragraph } from '@sovryn/ui';
import { translations } from '../../../locales/i18n';
import { t } from 'i18next';
import { AmountRenderer } from '../../molecules/AmountRenderer/AmountRenderer';
import { TOKEN_RENDER_PRECISION } from '../../../constants/currencies';
import { Decimal } from '@sovryn/utils';
import { UserTokensBalance } from './components/UserTokensBalance/UserTokensBalance';
import { TransactionDeposit } from '../../pages/LiquidityPage/types';
import { decimalic, fromWei } from '../../../utils/math';
import { getDecimalPrecision } from '../../../utils/helpers';

type UserStatisticsProps = {
  transactionData: TransactionDeposit[];
};
export const UserStatistics: FC<UserStatisticsProps> = ({
  transactionData,
}) => {
  const [open, toggle] = useReducer(v => !v, false);

  const totalTvlInUsd = useMemo(() => {
    const totalUSD = transactionData.reduce((total, balance) => {
      const decimalPrecision = getDecimalPrecision(balance.token);
      const amount = fromWei(balance.amount.toString(), decimalPrecision);
      const usdRate = balance.usdRate;
      return total.add(decimalic(amount).mul(usdRate));
    }, Decimal.ZERO);

    return totalUSD;
  }, [transactionData]);

  return (
    <>
      <div className="flex flex-col justify-start">
        <Paragraph
          className="font-medium text-gray-30 text-xs leading-3 mb-2"
          children={t(translations.rewardsPage.yourDeposits)}
        />

        <div className="text-2xl font-medium text-white lg:block hidden">
          <AmountRenderer
            value={totalTvlInUsd}
            prefix="$"
            precision={TOKEN_RENDER_PRECISION}
            showRoundingPrefix={false}
          />
        </div>

        <Accordion
          className="lg:hidden block"
          label={
            <div className="text-2xl font-medium text-white">
              <AmountRenderer
                value={totalTvlInUsd}
                prefix="$"
                precision={TOKEN_RENDER_PRECISION}
                showRoundingPrefix={false}
              />
            </div>
          }
          open={open}
          onClick={toggle}
          children={<UserTokensBalance transactionData={transactionData} />}
        />
      </div>

      <div className="lg:flex flex-col justify-between mt-2 lg:max-w-60 hidden">
        <UserTokensBalance transactionData={transactionData} />
      </div>
    </>
  );
};
