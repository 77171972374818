import React, { FC, useMemo } from 'react';
import { Paragraph } from '@sovryn/ui';
import { t } from 'i18next';
import { translations } from '../../../locales/i18n';
import { AmountRenderer } from '../../molecules/AmountRenderer/AmountRenderer';
import { TOKEN_RENDER_PRECISION } from '../../../constants/currencies';
import { Decimal } from '@sovryn/utils';
import { PREDEPOSITED_FUNDS_USD } from '../../../constants/general';
import { useGetFilteredTransactions } from './hooks/useGetFilteredTransactions';
import { decimalic, fromWei } from '../../../utils/math';
import { getDecimalPrecision } from '../../../utils/helpers';
import { useGetAllEthDeposits } from '../../../hooks/useGetAllEthDeposits';
import { useGetAllErc20Deposits } from '../../../hooks/useGetAllErc20Deposits';
import { useLoadContract } from '../../../hooks/useLoadContract';

export const GlobalStatistics: FC = () => {
  const { loading, pastAllEthDeposits } = useGetAllEthDeposits();
  const { loading: loadingTransactions, pastAllErc20Deposits } =
    useGetAllErc20Deposits();

  const gnosisSafeContractAddress =
    useLoadContract('gnosisSafeMultisig')?.address;

  const filteredTransactions = useGetFilteredTransactions(
    [...pastAllEthDeposits, ...pastAllErc20Deposits],
    gnosisSafeContractAddress,
  );

  const totalTvlInUsd = useMemo(() => {
    if (loading || loadingTransactions) {
      return Decimal.ZERO;
    }
    const totalUSD = filteredTransactions.reduce((total, balance) => {
      const decimalPrecision = getDecimalPrecision(balance.token);
      const amount = fromWei(balance.amount.toString(), decimalPrecision);
      const usdRate = balance.usdRate;
      return total.add(decimalic(amount).mul(usdRate));
    }, Decimal.ZERO);

    return totalUSD.add(PREDEPOSITED_FUNDS_USD);
  }, [filteredTransactions, loading, loadingTransactions]);

  return (
    <div className="flex flex-col justify-start">
      <Paragraph
        className="font-medium text-gray-30 text-xs leading-3 mb-2"
        children={t(translations.tvl)}
      />

      <div className="text-2xl font-medium text-white block">
        <AmountRenderer
          value={totalTvlInUsd}
          prefix="$"
          precision={TOKEN_RENDER_PRECISION}
          showRoundingPrefix={false}
        />
      </div>
    </div>
  );
};
