import { t } from 'i18next';

import { translations } from '../../../locales/i18n';

export type MenuItem = {
  text: string;
  url: string;
  submenu?: SubMenuItem[];
};

export type SubMenuItem = {
  text: string;
  label: string;
  url: string;
};

export const menuItemsMapping: MenuItem[] = [
  {
    text: t(translations.header.nav.deposit),
    url: '/',
  },
  {
    text: t(translations.header.nav.rewards),
    url: '/rewards',
  },
];
