import { useEffect, useState } from 'react';
import { useGetProtocolContract } from '../../../../hooks/useGetContract';
import { SupportedEthTokens } from '../../../../types/types';
import { ETH_TOKENS } from '../../../../contracts/tokens/eth';
import { currentNetwork } from '../../../../utils/helpers';

export const useGetTokenAllowed = (token: SupportedEthTokens) => {
  const lockDropContract = useGetProtocolContract('lockDrop');
  const [isTokenAllowed, setIsTokenAllowed] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!lockDropContract) {
        return;
      }

      const tokenAddress = ETH_TOKENS[token][currentNetwork];

      try {
        const isTokenAllowed = await lockDropContract.allowedTokens(
          tokenAddress,
        );

        setIsTokenAllowed(isTokenAllowed.isAllowed);
      } catch (error) {
        console.error('Error fetching contract status:', error);
        setIsTokenAllowed(false);
      }
    };

    fetchData();
  }, [lockDropContract, token]);

  return { isTokenAllowed };
};
