import { Environments } from '../types/global';

// Used for adding a new chain to a wallet
export const PUBLIC_ETH_RPC = {
  [Environments.Mainnet]: 'https://ethereum-rpc.publicnode.com',
  [Environments.Testnet]: 'https://ethereum-sepolia-rpc.publicnode.com',
};

export const ETH_EXPLORER = {
  [Environments.Mainnet]: 'https://etherscan.io',
  [Environments.Testnet]: 'https://sepolia.etherscan.io',
};

export const ESTIMATOR_URI = process.env.REACT_APP_ESTIMATOR_URI;
