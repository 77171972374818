import { useEffect, useState } from 'react';
import { SupportedEthTokens } from '../types/types';
import { Decimal } from '@sovryn/utils';
import { ETH_TOKENS } from '../contracts/tokens/eth';
import { Environments, TokenPriceData } from '../types/global';
import { bobBackendURL } from '../utils/helpers';

export const useGetUsdPrices = (token?: SupportedEthTokens) => {
  const [tokenPrices, setTokenPrices] = useState<TokenPriceData>({});
  const [usdPrice, setUsdPrice] = useState(Decimal.ONE);

  useEffect(() => {
    const fetchTokenPrices = async () => {
      try {
        const response = await fetch(`${bobBackendURL}/tokenprices`);

        if (!response.ok) {
          console.error('Error fetching token prices:', response.statusText);
          return;
        }

        const data = await response.json();

        const tokenPricesData: TokenPriceData = {};
        let usdPriceData: Decimal = Decimal.ONE;
        //reversed so we take latest price from BE call rather than first
        data.reverse().forEach(tokenData => {
          if (
            token &&
            tokenData.token_address?.toLowerCase() ===
              ETH_TOKENS[token][Environments.Mainnet]?.toLowerCase()
          ) {
            usdPriceData = Decimal.from(tokenData.price);
          } else {
            tokenPricesData[tokenData.token_address.toLowerCase()] =
              tokenData.price;
          }
        });
        setTokenPrices(tokenPricesData);

        setUsdPrice(usdPriceData || Decimal.ONE);
      } catch (error) {
        console.error('Error fetching token prices:', error);
      }
    };
    fetchTokenPrices();
  }, [token]);

  return { tokenPrices, usdPrice };
};
