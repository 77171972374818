import React, { FC } from 'react';

import { TransactionDeposit } from '../../../LiquidityPage/types';
import { PointItem } from '../../PointItem/PointItem';

type TotalPointsProps = {
  transactions: TransactionDeposit[];
};

export const Points: FC<TotalPointsProps> = ({ transactions }) =>
  transactions.map((transaction, index) => (
    <PointItem key={index} transaction={transaction} />
  ));
