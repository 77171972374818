import React, { FC } from 'react';
import { translations } from '../../../../../locales/i18n';
import { t } from 'i18next';
import styles from './CountdownMiniRenderer.module.css';

const { counter } = translations;

type CountdownMiniRendererProps = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const CountdownMiniRenderer: FC<CountdownMiniRendererProps> = ({
  days,
  hours,
  minutes,
  seconds,
}) => (
  <div className={styles.countdown}>
    <div>
      <span>{days}</span>
      <span>{t(counter.days)}</span>
    </div>
    <div>
      <span>{hours}</span>
      <span>{t(counter.hours)}</span>
    </div>
    <div>
      <span>{minutes}</span>
      <span>{t(counter.minutes)}</span>
    </div>
    <div>
      <span>{seconds}</span>
      <span>{t(counter.seconds)}</span>
    </div>
  </div>
);
