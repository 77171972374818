import React, { FC } from 'react';

import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

import { applyDataAttr } from '@sovryn/ui';
import { Header } from '../../organisms/Header/Header';
import { Footer } from '../../organisms/Footer/Footer';
type PageContainerProps = {
  className?: string;
  contentClassName?: string;
  dataAttribute?: string;
};

export const PageContainer: FC<PageContainerProps> = ({
  className,
  contentClassName,
  dataAttribute,
}) => {
  return (
    <div
      className={classNames('flex flex-col flex-grow', className)}
      {...applyDataAttr(dataAttribute)}
    >
      <>
        <Header />
        <div className={classNames('px-4 flex flex-grow', contentClassName)}>
          <Outlet />
        </div>
      </>
      <div className="shrink">
        <Footer />
      </div>
    </div>
  );
};
