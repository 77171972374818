import { ethers } from 'ethers';

import { ChainId } from '@sovryn/ethers-provider';

import { DEFAULT_CHAIN_ID } from '../config/chains';

import { useLoadContract } from './useLoadContract';

export const useGetProtocolContract = (
  contractName: string,
  chain: ChainId = DEFAULT_CHAIN_ID,
  customSigner?: ethers.providers.JsonRpcSigner,
) => useLoadContract(contractName, chain, customSigner);
