import { useEffect, useMemo, useState } from 'react';
import { SupportedEthTokens } from '../types/types';
import { bobBackendURL, currentNetwork } from '../utils/helpers';
import { ETH_TOKENS } from '../contracts/tokens/eth';
import { useAccount } from './useAccount';
import { Decimal } from '@sovryn/utils';

export const useGetPoints = (
  token: SupportedEthTokens,
  timestamp: number,
  amount: Decimal,
) => {
  const { account } = useAccount();
  const [points, setPoints] = useState(0);
  const tokenAddress = useMemo(
    () => ETH_TOKENS[token][currentNetwork],
    [token],
  );

  useEffect(() => {
    const fetchPoints = async () => {
      if (!tokenAddress || !timestamp || Number(amount) <= 0) {
        return;
      }
      try {
        const response = await fetch(
          `${bobBackendURL}/points?tokenAddress=${tokenAddress}&ts=${timestamp}&amount=${amount.toString()}`,
        );
        if (response.ok) {
          const points = await response.json();
          if (points) {
            setPoints(points);
          }
        }
      } catch (error) {
        console.error('Error fetching points:', error);
        setPoints(0);
      }
    };
    fetchPoints();
  }, [tokenAddress, timestamp, amount]);

  useEffect(() => {
    if (!account) {
      setPoints(0);
    }
  }, [account]);

  return { points };
};
