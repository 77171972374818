import {
  TransactionDeposit,
  TransactionDepositData,
} from '../../../pages/LiquidityPage/types';
import { decimalic } from '../../../../utils/math';
import { useGetUsdPrices } from '../../../../hooks/useGetUsdPrices';
import { SupportedEthTokens } from '../../../../types/types';
import { ETH_TOKENS } from '../../../../contracts/tokens/eth';
import { currentNetwork } from '../../../../utils/helpers';
import { Decimal } from '@sovryn/utils';

export const useGetFilteredTransactions = (
  transactions: TransactionDepositData[],
  safeContractAddress: string | undefined,
): TransactionDeposit[] => {
  const depositsMap = new Map<string, TransactionDeposit>();
  const { tokenPrices } = useGetUsdPrices();

  transactions.forEach(tx => {
    const isNativeToken = tx.decoded.name === 'SafeReceived';

    if (
      isNativeToken
        ? tx.sender_address.toLowerCase() !== safeContractAddress?.toLowerCase()
        : tx.decoded.params[1].value.toLowerCase() !==
          safeContractAddress?.toLowerCase()
    ) {
      return;
    }

    const token = isNativeToken
      ? SupportedEthTokens.eth
      : tx.sender_contract_ticker_symbol.toLowerCase();
    const tokenAddress = isNativeToken
      ? ETH_TOKENS[SupportedEthTokens.eth][currentNetwork]
      : tx.sender_address;
    let amount: Decimal = Decimal.ZERO;

    tx.decoded.params.forEach(param => {
      if (param.name === 'value') {
        amount = amount.add(param.value);
      }
    });

    if (depositsMap.has(token)) {
      const deposit = depositsMap.get(token);
      if (deposit) {
        deposit.amount = decimalic(deposit.amount).add(amount);
      }
    } else {
      if (SupportedEthTokens[token.toLowerCase()]) {
        const newDeposit: TransactionDeposit = {
          amount: amount,
          usdRate: decimalic(tokenPrices[tokenAddress.toLowerCase()] || 1),
          token,
          tokenAddress,
        };
        depositsMap.set(token, newDeposit);
      }
    }
  });

  return Array.from(depositsMap.values());
};
