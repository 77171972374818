import { useEffect, useState } from 'react';
import { useGetProtocolContract } from '../../../../hooks/useGetContract';

export const useGetSafeModuleStatus = () => {
  const safeModuleContract = useGetProtocolContract('safeModuleContract');

  const [safeModuleContractStatus, setSafeModuleContract] = useState({
    isPaused: true,
    isStopped: true,
  });

  useEffect(() => {
    const fetchStatus = async () => {
      if (!safeModuleContract) {
        return;
      }

      try {
        const isPaused = await safeModuleContract.isPaused();
        const isStopped = await safeModuleContract.isStopped();

        setSafeModuleContract({
          isPaused: isPaused,
          isStopped: isStopped,
        });
      } catch (error) {
        console.error('Error fetching contract status:', error);
        setSafeModuleContract({
          isPaused: true,
          isStopped: true,
        });
      }
    };

    fetchStatus();
  }, [safeModuleContract]);

  return { safeModuleContractStatus };
};
