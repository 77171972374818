export const SOCIAL_LINKS = {
  DISCORD: 'https://discord.gg/sovryn',
  TELEGRAM: 'https://t.me/SovrynBitcoin',
  TWITTER: 'https://x.com/SovrynBTC',
};

export const GITHUB_LINKS = {
  ORGANIZATION: 'https://github.com/DistributedCollective',
  DAPP: 'https://github.com/DistributedCollective/sovryn-dapp',
};

export const WEBSITE_LINKS = {
  ROOT: 'https://sovryn.com',
  BLOG: 'https://sovryn.com/all-things-sovryn',
  DAPP: 'https://sovryn.app',
  POWA: 'https://sovryn.app/powa',
  SPICE: 'https://sovryn.app/bob-lp-points',
  REWARDS: 'https://sovryn.com/extra-spice',
};

export const WIKI_LINKS = {
  ROOT: 'https://wiki.sovryn.com',
  SECURITY: 'https://wiki.sovryn.com/technical-documents#security',
  FEES: 'https://wiki.sovryn.com/sovryn-dapp/fees#zero-borrowing',
  RISKS: 'https://wiki.sovryn.com/en/sovryn-dapp/impermanent-loss',
  SPICE: 'https://wiki.sovryn.com/en/spice-campaign',
  ESOV_DEPOSIT: 'https://wiki.sovryn.com/en/spice-campaign#esov-deposits',
};

export const HELPDESK_LINK = 'https://help.sovryn.app/';

export const BOB_FUSION_LINK_INC_REF =
  'https://fusion.gobob.xyz/?refCode=u0i6lk';
