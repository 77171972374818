import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { translations } from '../../../locales/i18n';
import {
  AmountInput,
  Button,
  ButtonSize,
  ButtonStyle,
  ButtonType,
  Checkbox,
  ErrorBadge,
  ErrorLevel,
  Heading,
  Link,
  NotificationType,
  Paragraph,
  ParagraphSize,
  Select,
} from '@sovryn/ui';
import { MaxButton } from '../../molecules/MaxButton/MaxButton';
import { useAccount } from '../../../hooks/useAccount';
import { useAssetBalance } from '../../../hooks/useAssetBalance';
import { SupportedEthTokens } from '../../../types/types';
import { Counter } from '../../organisms/Counter/Counter';
import { getAssetRenderer, tokenOptions } from './LiquidityPage.constants';
import { AmountRenderer } from '../../molecules/AmountRenderer/AmountRenderer';
import { DepositTokens } from '../../molecules/DepositTokens/DepositTokens';
import { BOB_FUSION_LINK_INC_REF, WIKI_LINKS } from '../../../constants/links';
import { useHandleDeposit } from './hooks/useHandleDeposit';
import { decimalic } from '../../../utils/math';
import { useNotificationContext } from '../../../contexts/NotificationContext';
import { nanoid } from 'nanoid';
import { SupportedEthTokensData } from '../../../contracts/tokenDetails';
import { Decimal } from '@sovryn/utils';
import { DEPOSIT_CLOSE_DATE } from '../../../constants/general';
import dayjs from 'dayjs';
import { TOKEN_RENDER_PRECISION } from '../../../constants/currencies';
import {
  EXTRA_SPICE_POINTS_MULTIPLIER,
  RUNES_POINTS_MULTIPLIER,
} from '../../../constants/general';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetLockDropStatus } from './hooks/useGetLockDropStatus';
import { useGetSafeModuleStatus } from './hooks/useGetSafeModuleStatus';
import { useGetTokenAllowed } from './hooks/useGetTokenAllowed';
import { GlobalStatistics } from '../../organisms/GlobalStatistics/GlobalStatistics';
import { useGetAllEthDeposits } from '../../../hooks/useGetAllEthDeposits';
import { useDispatch } from 'react-redux';
import { useGetAllErc20Deposits } from '../../../hooks/useGetAllErc20Deposits';
import {
  addEthTransactions,
  addErc20Transactions,
  clearTransactions,
} from './slice';
import { useGetUsdPrices } from '../../../hooks/useGetUsdPrices';

const pageTranslations = translations.liquidityPage;
const commonTranslations = translations.common;

export const LiquidityPage = () => {
  const { account } = useAccount();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [amount, setAmount] = useState('');

  const { pastAllEthDeposits, loading: loadingEthDeposits } =
    useGetAllEthDeposits();
  const { pastAllErc20Deposits, loading: loadingErc20Deposits } =
    useGetAllErc20Deposits();

  const { lockDropContractStatus } = useGetLockDropStatus();
  const { safeModuleContractStatus } = useGetSafeModuleStatus();

  const [spiceAmount, setSpiceAmount] = useState(Decimal.ZERO);
  const { addNotification } = useNotificationContext();

  const [token, setToken] = useState<SupportedEthTokens>(
    SupportedEthTokens.eth,
  );

  const { isTokenAllowed } = useGetTokenAllowed(token);

  const { balance } = useAssetBalance(token);

  const multiplier = useMemo(() => {
    const selectedToken = SupportedEthTokensData.find(
      item => item.symbol === token,
    );
    return selectedToken?.multiplier ?? 1;
  }, [token]);

  const { usdPrice } = useGetUsdPrices(token);

  const remainingDays = useMemo(() => {
    const remainingHours = dayjs(DEPOSIT_CLOSE_DATE).diff(dayjs(), 'hour');
    return Math.max(0, remainingHours / 24);
  }, []);

  const extraSpicePoints = useMemo(
    () => spiceAmount.mul(EXTRA_SPICE_POINTS_MULTIPLIER),
    [spiceAmount],
  );
  const runesPoints = useMemo(
    () => extraSpicePoints.mul(RUNES_POINTS_MULTIPLIER),
    [extraSpicePoints],
  );

  const totalRewards = useMemo(() => {
    if (!amount || !usdPrice || !multiplier) {
      return Decimal.ZERO;
    }
    const rewards = decimalic(amount).mul(usdPrice).mul(multiplier);
    const remainingPeriods = Math.ceil(remainingDays);
    const roundedTotalRewards = rewards.mul(remainingPeriods);
    return roundedTotalRewards;
  }, [amount, usdPrice, multiplier, remainingDays]);

  const [maximumAmount, setMaximumAmount] = useState('');
  const [disclaimer, setDisclaimer] = useState(false);

  const isValidAmount = useMemo(
    () => Number(amount) <= Number(maximumAmount),
    [amount, maximumAmount],
  );

  const isSubmitDisabled = useMemo(
    () =>
      !account ||
      !amount ||
      Number(amount) <= 0 ||
      Number(amount) > Number(maximumAmount) ||
      !disclaimer ||
      loadingEthDeposits ||
      loadingErc20Deposits ||
      !isValidAmount ||
      !isTokenAllowed ||
      lockDropContractStatus.isPaused ||
      lockDropContractStatus.isWithdrawalTimeStarted ||
      safeModuleContractStatus.isPaused ||
      safeModuleContractStatus.isStopped,
    [
      account,
      amount,
      loadingErc20Deposits,
      loadingEthDeposits,
      maximumAmount,
      disclaimer,
      isTokenAllowed,
      isValidAmount,
      lockDropContractStatus.isPaused,
      lockDropContractStatus.isWithdrawalTimeStarted,
      safeModuleContractStatus.isPaused,
      safeModuleContractStatus.isStopped,
    ],
  );

  const handleReset = useCallback(() => {
    setAmount('');
    setToken(SupportedEthTokens.eth);
    setDisclaimer(false);
    setSpiceAmount(Decimal.ZERO);
  }, []);

  const handleTokenChange = useCallback(value => {
    setToken(value);
    setAmount('');
  }, []);

  const handleComplete = useCallback(() => {
    handleReset();
    addNotification({
      type: NotificationType.success,
      title: t(translations.liquidityPage.txDialog.depositSuccess),
      content: '',
      dismissible: true,
      id: nanoid(),
    });
  }, [addNotification, handleReset]);

  const handleDeposit = useHandleDeposit(
    decimalic(amount),
    token,
    handleComplete,
  );

  const handleSubmit = useCallback(() => {
    if (isSubmitDisabled) {
      return;
    }
    handleDeposit();
  }, [isSubmitDisabled, handleDeposit]);

  const onMaximumAmountClick = useCallback(() => {
    setAmount(maximumAmount);
  }, [maximumAmount]);

  useEffect(() => {
    if (balance) {
      setMaximumAmount(balance.toString());
    }
  }, [balance]);

  useEffect(() => {
    if (Number(amount) > 0 && usdPrice.gt(0)) {
      setSpiceAmount(totalRewards);
    }
  }, [amount, usdPrice, totalRewards]);

  useEffect(() => {
    if (account) {
      handleReset();
    }
  }, [account, handleReset]);

  useEffect(() => {
    if (Number(amount) === 0) {
      setSpiceAmount(Decimal.ZERO);
    }
  }, [amount]);

  useEffect(() => {
    dispatch(clearTransactions());
    if (
      pastAllEthDeposits.length > 0 &&
      pastAllErc20Deposits.length > 0 &&
      !loadingErc20Deposits &&
      !loadingEthDeposits
    ) {
      dispatch(addEthTransactions(pastAllEthDeposits));
      dispatch(addErc20Transactions(pastAllErc20Deposits));
    }
  }, [
    pastAllEthDeposits,
    pastAllErc20Deposits,
    loadingErc20Deposits,
    loadingEthDeposits,
    dispatch,
  ]);

  return (
    <div className="max-w-6xl w-full mx-auto mb-5">
      <Helmet>
        <title>{t(pageTranslations.meta.title)}</title>
      </Helmet>

      <Counter />

      <div className="w-full lg:flex lg:justify-between items-start text-gray-10 lg:mt-9 mt-11 lg:px-20">
        <div className="lg:max-w-[25rem] lg:px-4 mb-4 sm:max-w-3/4 lg:ml-0 mx-auto">
          <Heading className="text-2xl font-medium mb-8 lg:mb-0">
            {t(pageTranslations.title)}
          </Heading>
          <Paragraph
            size={ParagraphSize.small}
            className="mt-2.5 sm:mt-8 text-sm leading-5 font-medium max-w-xl"
            children={<Trans i18nKey={t(pageTranslations.subtitle)} />}
          />

          <div className="flex gap-4 items-center mt-6 mb-7">
            <Button
              type={ButtonType.button}
              size={ButtonSize.large}
              style={ButtonStyle.secondary}
              text={t(translations.common.learnMore)}
              href={WIKI_LINKS.SPICE}
              hrefExternal
            />
            <Button
              text={t(translations.common.buttons.checkPoints)}
              className="ml-4 text-sm"
              style={ButtonStyle.ghost}
              onClick={() => navigate('/rewards')}
              hrefExternal
            />
          </div>
          <div className="px-4 py-4 rounded bg-gray-80">
            <Heading className="text-xl font-medium mb-8 lg:mb-4">
              {t(pageTranslations.sovDeposits.title)}
            </Heading>
            <div className="text-sm font-normal">
              <Trans
                i18nKey={t(pageTranslations.sovDeposits.description)}
                components={[
                  <Link
                    text={t(pageTranslations.sovDeposits.descriptionLinkText)}
                    href={BOB_FUSION_LINK_INC_REF}
                    className="text-sm"
                  />,
                ]}
              />
              <Link
                text={t(pageTranslations.sovDeposits.readMoreLink)}
                href={WIKI_LINKS.ESOV_DEPOSIT}
                className="text-sm block mt-4"
              />
            </div>
          </div>
        </div>

        <div className="lg:max-w-[30rem] sm:max-w-3/4 px-3 lg:mx-0 mx-auto xl:min-w-[30rem]">
          <GlobalStatistics />
          <div className="px-4 sm:px-6 py-6 rounded bg-gray-80 mt-2">
            <>
              <Paragraph
                size={ParagraphSize.base}
                children={t(pageTranslations.form.depositFrom)}
                className="font-medium"
              />

              <DepositTokens />

              <div className="mt-6">
                <Select
                  value={token}
                  onChange={handleTokenChange}
                  options={tokenOptions}
                  labelRenderer={() => getAssetRenderer(token)}
                  className="min-w-[6.7rem] w-full mb-6"
                  menuClassName="max-h-[10rem] sm:max-h-[20rem]"
                />
                {account && isTokenAllowed && (
                  <div className="flex justify-end mb-1">
                    <MaxButton
                      onClick={onMaximumAmountClick}
                      value={maximumAmount}
                      token={token}
                    />
                  </div>
                )}
                <AmountInput
                  value={amount}
                  onChangeText={setAmount}
                  label={t(commonTranslations.amount)}
                  min={0}
                  invalid={!isValidAmount}
                  disabled={!account || !isTokenAllowed}
                  className="w-full flex-grow-0 flex-shrink max-w-full "
                  placeholder="0"
                  unit={token.toUpperCase()}
                />
              </div>

              {!isValidAmount && (
                <ErrorBadge
                  level={ErrorLevel.Critical}
                  message={t(pageTranslations.form.invalidAmountError)}
                  dataAttribute="deposit-amount-error"
                />
              )}

              {!isTokenAllowed && (
                <ErrorBadge
                  level={ErrorLevel.Critical}
                  message={t(pageTranslations.form.tokenNotAllowedError)}
                  dataAttribute="token-not-allowed-error"
                />
              )}

              <div className="rounded p-3 bg-gray-70 mt-6">
                <div className="flex justify-between items-center font-medium text-gray-30 text-xs">
                  <Paragraph
                    children={t(pageTranslations.form.estimatedSpicePoints)}
                  />
                  <AmountRenderer
                    value={spiceAmount}
                    precision={TOKEN_RENDER_PRECISION}
                  />
                </div>

                <div className="mt-2 flex justify-between items-center font-medium text-gray-30 text-xs">
                  <Paragraph
                    children={t(
                      pageTranslations.form.estimatedExtraSpicePoints,
                    )}
                  />
                  <AmountRenderer
                    value={extraSpicePoints}
                    precision={TOKEN_RENDER_PRECISION}
                  />
                </div>

                <div className="mt-2 flex justify-between items-center font-medium text-gray-30 text-xs">
                  <Paragraph
                    children={t(pageTranslations.form.estimatedRunesPoints)}
                  />
                  <AmountRenderer
                    value={runesPoints}
                    precision={TOKEN_RENDER_PRECISION}
                  />
                </div>
              </div>
            </>
            <div className="mt-6">
              <Checkbox
                checked={disclaimer}
                onChangeValue={setDisclaimer}
                label={
                  <Trans
                    i18nKey={t(pageTranslations.disclaimer)}
                    components={[
                      <Link
                        text={t(pageTranslations.disclaimerCTA)}
                        href={WIKI_LINKS.RISKS}
                      />,
                    ]}
                  />
                }
              />
            </div>
            <Button
              type={ButtonType.reset}
              style={ButtonStyle.primary}
              size={ButtonSize.large}
              text={t(commonTranslations.buttons.deposit)}
              className="w-full mt-8"
              disabled={isSubmitDisabled}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
