import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { OnboardProvider } from '@sovryn/onboard-react';

// chain config must be imported before other internal files
import './config/chains';

import { router } from './router';
import './locales/dayjs';
import './locales/i18n';
import './styles/tailwindcss/index.css';
import { TransactionProvider } from './contexts/TransactionContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { TransactionStepDialog } from './app/organisms/TransactionStepDialog';
import { Provider } from 'react-redux';
import store from './store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <TransactionProvider>
        <NotificationProvider>
          <HelmetProvider>
            <RouterProvider router={router} />
            <OnboardProvider dataAttribute="dapp-onboard" />
          </HelmetProvider>
          <TransactionStepDialog disableFocusTrap />
        </NotificationProvider>
      </TransactionProvider>
    </Provider>
  </React.StrictMode>,
);
