import setup, { Chain, ChainIds } from '@sovryn/ethers-provider';
import { ChainId } from '@sovryn/ethers-provider';
import { isMainnet } from '../utils/helpers';
import { ETH_EXPLORER, PUBLIC_ETH_RPC } from '../constants/infrastructure';
import { Environments } from '../types/global';

export const ETH_CHAIN_ID = '0x1' as ChainId;

export const SAFE_CLIENT_CHAIN_ID = (isMainnet() ? '1' : '11155111') as ChainId;

export const DEFAULT_CHAIN_ID = (
  isMainnet() ? ETH_CHAIN_ID : ChainIds.SEPOLIA
) as ChainId;

export const APP_CHAIN_LIST: Chain[] = [
  ...(isMainnet()
    ? [
        {
          id: ETH_CHAIN_ID,
          label: 'ETH',
          token: 'ETH',
          publicRpcUrl: PUBLIC_ETH_RPC[Environments.Mainnet],
          rpcUrl: PUBLIC_ETH_RPC[Environments.Mainnet],
          blockExplorerUrl: ETH_EXPLORER[Environments.Mainnet],
        },
      ]
    : [
        {
          id: ChainIds.SEPOLIA,
          label: 'ETH Testnet',
          token: 'tETH',
          publicRpcUrl: PUBLIC_ETH_RPC[Environments.Testnet],
          rpcUrl: PUBLIC_ETH_RPC[Environments.Testnet],
          blockExplorerUrl: ETH_EXPLORER[Environments.Testnet],
        },
      ]),
];

setup(APP_CHAIN_LIST);
