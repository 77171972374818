import { Paragraph, ParagraphSize } from '@sovryn/ui';
import { t } from 'i18next';
import React, { FC } from 'react';
import { translations } from '../../../locales/i18n';
import Countdown from 'react-countdown';
import { CountdownMiniRenderer } from '../Counter/components/CountdownMiniRenderer/CountdownMiniRenderer';

type MultiCounterProps = {
  spiceTimestamp?: string;
  extraSpiceTimestamp?: string;
  runesTimestamp?: string;
};

export const MultiCounter: FC<MultiCounterProps> = ({
  spiceTimestamp,
  extraSpiceTimestamp,
  runesTimestamp,
}) => {
  if (!spiceTimestamp || !extraSpiceTimestamp || !runesTimestamp) {
    return null;
  }

  return (
    <div className="lg:p-3 rounded lg:bg-gray-80 mt-9 w-full lg:max-w-md mx-auto">
      <Paragraph
        children={t(translations.counter.timeLeftTilWithdrawal)}
        className="font-medium text-gray-30 text-base lg:mb-3 mb-4 text-center"
      />
      <div className="bg-gray-80 p-3 rounded">
        <div className="flex flex-col lg:flex-row justify-between items-center font-medium text-gray-30">
          <Paragraph
            children={t(translations.rewardsPage.table.spice)}
            size={ParagraphSize.base}
            className="lg:mb-0 mb-1.5"
          />
          <Countdown
            date={spiceTimestamp}
            renderer={props => <CountdownMiniRenderer {...props} />}
          />
        </div>
        <div className="mt-1.5 flex flex-col lg:flex-row justify-between items-center font-medium text-gray-30">
          <Paragraph
            children={t(translations.rewardsPage.table.extraSpice)}
            size={ParagraphSize.base}
            className="lg:mb-0 mb-1.5"
          />
          <Countdown
            date={extraSpiceTimestamp}
            renderer={props => <CountdownMiniRenderer {...props} />}
          />
        </div>
        <div className="mt-1.5 flex flex-col lg:flex-row justify-between items-center font-medium text-gray-30">
          <Paragraph
            children={t(translations.rewardsPage.table.runes)}
            size={ParagraphSize.base}
            className="lg:mb-0 mb-1.5"
          />
          <Countdown
            date={runesTimestamp}
            renderer={props => <CountdownMiniRenderer {...props} />}
          />
        </div>
      </div>
    </div>
  );
};
