import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { PageContainer } from './app/templates/PageContainer/PageContainer';
import { ErrorPage } from './app/pages/ErrorPage/ErrorPage';
import { RewardsPage } from './app/pages/RewardsPage/RewardsPage';
import { LiquidityPage } from './app/pages/LiquidityPage/LiquidityPage';

const routes = [
  {
    path: '/',
    element: (
      <PageContainer className="flex flex-col" contentClassName="flex-col" />
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/rewards',
        element: <RewardsPage />,
      },
      {
        index: true,
        element: <LiquidityPage />,
      },
    ],
  },
];

export const router = createBrowserRouter(routes);
