import React, { FC, PropsWithChildren, useCallback } from 'react';

import { t } from 'i18next';
import { nanoid } from 'nanoid';
import { Button, NotificationType, WalletIdentity } from '@sovryn/ui';

import { translations } from '../../../locales/i18n';
import { useNotificationContext } from '../../../contexts/NotificationContext';

type ConnectWalletButtonProps = {
  onConnect: () => void;
  onDisconnect: () => void;
  address: string | undefined;
  pending?: boolean;
  className?: string;
  dataAttribute?: string;
};

export const ConnectWalletButton: FC<
  PropsWithChildren<ConnectWalletButtonProps>
> = ({
  address,
  pending,
  onDisconnect,
  onConnect,
  className,
  dataAttribute,
}) => {
  const { addNotification } = useNotificationContext();

  const onCopyAddress = useCallback(() => {
    addNotification({
      type: NotificationType.success,
      title: t(translations.copyAddress),
      content: '',
      dismissible: true,
      id: nanoid(),
    });
  }, [addNotification]);

  if (!address) {
    return (
      <Button
        text={t(translations.connectWalletButton.connect)}
        onClick={onConnect}
        className={className}
        dataAttribute={dataAttribute}
        disabled={pending}
      />
    );
  } else {
    return (
      <>
        <WalletIdentity
          onDisconnect={onDisconnect}
          onCopyAddress={onCopyAddress}
          address={address}
          dataAttribute={dataAttribute}
          className={className}
          submenuLabels={{
            copyAddress: t(translations.connectWalletButton.copyAddress),
            disconnect: t(translations.connectWalletButton.disconnect),
          }}
        />
      </>
    );
  }
};
