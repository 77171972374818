import React, { FC } from 'react';
import { SupportedEthTokensData } from '../../../../../contracts/tokenDetails';
import { TOKEN_RENDER_PRECISION } from '../../../../../constants/currencies';
import { AmountRenderer } from '../../../../molecules/AmountRenderer/AmountRenderer';
import { AssetRenderer } from '../../../../molecules/AssetRenderer/AssetRenderer';
import { TransactionDeposit } from '../../../../pages/LiquidityPage/types';
import { decimalic, fromWei } from '../../../../../utils/math';
import { getDecimalPrecision } from '../../../../../utils/helpers';

type UserTokensBalanceProps = {
  transactionData: TransactionDeposit[];
};

export const UserTokensBalance: FC<UserTokensBalanceProps> = ({
  transactionData,
}) => (
  <div className="flex flex-col justify-between mt-2 lg:max-w-60">
    {Object.values(SupportedEthTokensData).map(token => {
      const tokenTransaction = transactionData.find(
        transaction => transaction.token === token.symbol,
      );

      const decimalPrecision = getDecimalPrecision(token.symbol);
      const tokenAmountUsd = tokenTransaction
        ? decimalic(
            fromWei(tokenTransaction.amount.toString(), decimalPrecision),
          ).mul(tokenTransaction?.usdRate || 1)
        : 0;

      return (
        <div key={token.symbol} className="mb-2">
          <div className="flex items-center justify-between">
            <AssetRenderer
              showAssetLogo
              assetClassName="font-semibold"
              asset={token.symbol}
            />

            <div className="text-semibold text-gray-30 text-xs">
              <AmountRenderer
                value={tokenAmountUsd.toString()}
                prefix="$"
                precision={TOKEN_RENDER_PRECISION}
                showRoundingPrefix={false}
              />
            </div>
          </div>
        </div>
      );
    })}
  </div>
);
