import React from 'react';
import { SupportedEthTokensData } from '../../../contracts/tokenDetails';
import { AssetRenderer } from '../../molecules/AssetRenderer/AssetRenderer';
import { SupportedEthTokens } from '../../../types/types';
import dayjs from 'dayjs';
import {
  DEPOSIT_CLOSE_DATE,
  DEPOSIT_OPEN_DATE,
} from '../../../constants/general';

export const LEARN_MORE_URL = '';

export const tokenOptions = Object.values(SupportedEthTokensData).map(
  token => ({
    value: token.symbol,
    label: (
      <AssetRenderer
        showAssetLogo
        asset={token.symbol}
        assetClassName="font-medium"
      />
    ),
  }),
);

export const getAssetRenderer = (token: SupportedEthTokens) => (
  <AssetRenderer showAssetLogo asset={token} assetClassName="font-medium" />
);

export const IS_DEPOSIT_OPEN = dayjs().isAfter(dayjs(DEPOSIT_OPEN_DATE));
export const IS_DEPOSIT_OVER = dayjs().isAfter(dayjs(DEPOSIT_CLOSE_DATE));
