import { Decimal } from '@sovryn/utils';
import { SupportedEthTokens } from './types';

export enum Environments {
  Testnet = 'testnet',
  Mainnet = 'mainnet',
}

export enum Services {
  Notification = 'notification',
  Amm = 'amm',
}

export type Nullable<T = any> = T | null;

export type PastDepositsType = {
  token: SupportedEthTokens;
  amount: Decimal;
  usdRate?: Decimal;
  timestamp?: number;
  // id?: string;
  // amount: Decimal;
  // usdRate: Decimal;
  sender?: string;
  // timestamp: number;
  // token: string;
  // tokenAddress?: SupportedEthTokens;
};

export type TokenPriceData = {
  [tokenAddress: string]: string;
};
