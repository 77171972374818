import { t } from 'i18next';
import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { translations } from '../../../locales/i18n';
import {
  Button,
  ButtonStyle,
  Heading,
  Link,
  Paragraph,
  ParagraphSize,
} from '@sovryn/ui';
import { WEBSITE_LINKS } from '../../../constants/links';
import { MultiCounter } from '../../organisms/MultiCounter/MultiCounter';
import {
  EXTRA_SPICE_POINTS_END_DATE,
  RUNES_POINTS_END_DATE,
  SPICE_POINTS_END_DATE,
} from '../../../constants/general';
import { GlobalStatistics } from '../../organisms/GlobalStatistics/GlobalStatistics';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../reducer';
import { UserStatistics } from '../../organisms/UserStatistics/UserStatistics';
import { useGetAllEthDeposits } from '../../../hooks/useGetAllEthDeposits';
import { useGetAllErc20Deposits } from '../../../hooks/useGetAllErc20Deposits';
import {
  addErc20Transactions,
  addEthTransactions,
  clearTransactions,
} from '../LiquidityPage/slice';
import { useAccount } from '../../../hooks/useAccount';
import { Points } from './components/Points/Points';
import { useLoadContract } from '../../../hooks/useLoadContract';
import { useGetUserFilteredTransactions } from '../../organisms/UserStatistics/hooks/useGetUserFilteredTransactions';
import { useGetPointsFilteredTransactions } from '../../organisms/UserStatistics/hooks/useGetPointsFilteredTransactions';
import { clearPoints } from './slice';

const pageTranslations = translations.rewardsPage;

export const RewardsPage: FC = () => {
  const dispatch = useDispatch();
  const { account } = useAccount();
  const { loading: loadingEthDeposits, pastAllEthDeposits } =
    useGetAllEthDeposits();
  const { loading: loadingErc20Deposits, pastAllErc20Deposits } =
    useGetAllErc20Deposits();

  const gnosisSafeContractAddress =
    useLoadContract('gnosisSafeMultisig')?.address;

  const filteredTransactions = useGetUserFilteredTransactions(
    [...pastAllEthDeposits, ...pastAllErc20Deposits],
    gnosisSafeContractAddress,
    account,
  );

  const pointsTransactions = useGetPointsFilteredTransactions(
    [...pastAllEthDeposits, ...pastAllErc20Deposits],
    gnosisSafeContractAddress,
    account,
  );

  const pointsData = useSelector((state: RootState) => state.pointsData);

  useEffect(() => {
    dispatch(clearTransactions());
    if (
      pastAllEthDeposits.length > 0 &&
      pastAllErc20Deposits.length > 0 &&
      !loadingErc20Deposits &&
      !loadingEthDeposits &&
      account
    ) {
      dispatch(addEthTransactions(pastAllEthDeposits));
      dispatch(addErc20Transactions(pastAllErc20Deposits));
    }
  }, [
    pastAllEthDeposits,
    pastAllErc20Deposits,
    loadingErc20Deposits,
    loadingEthDeposits,
    dispatch,
    account,
  ]);

  useEffect(() => {
    if (!account) {
      dispatch(clearPoints());
    }
  }, [account, dispatch]);

  useEffect(() => {
    if (account && pointsData.points !== 0 && pointsTransactions.length === 0) {
      dispatch(clearPoints());
    }
  }, [account, pointsData.points, pointsTransactions, dispatch]);

  return (
    <div className="max-w-[50.375rem] w-full mx-auto mb-5">
      <Helmet>
        <title>{t(pageTranslations.meta.title)}</title>
      </Helmet>

      <div className="w-full flex flex-col items-center text-gray-10 mt-9">
        <Heading className="text-2xl font-medium mb-3">
          {t(pageTranslations.title)}
        </Heading>
        <Paragraph
          size={ParagraphSize.small}
          className="mb-3 text-sm leading-5 font-semibold"
        >
          {t(pageTranslations.subtitle)}
        </Paragraph>
        <Button
          text={t(translations.common.buttons.learnMore)}
          className="text-sm"
          style={ButtonStyle.ghost}
          href={WEBSITE_LINKS.REWARDS}
          hrefExternal
        />

        <MultiCounter
          spiceTimestamp={SPICE_POINTS_END_DATE}
          extraSpiceTimestamp={EXTRA_SPICE_POINTS_END_DATE}
          runesTimestamp={RUNES_POINTS_END_DATE}
        />

        <Points transactions={pointsTransactions} />
        <Link
          href={WEBSITE_LINKS.SPICE}
          className="text-sm mt-4"
          text={t(translations.rewardsPage.checkSpicePoints)}
        />

        <div className="lg:flex flex-row justify-between w-full lg:mt-16 mt-6 lg:px-14 px-0">
          <div className="flex flex-col lg:max-w-60 w-full mb-6">
            <GlobalStatistics />
          </div>
          <div className="flex flex-col lg:max-w-60 w-full">
            <UserStatistics transactionData={filteredTransactions} />
          </div>
        </div>
      </div>
    </div>
  );
};
