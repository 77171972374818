import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TransactionDepositData, TransactionsState } from './types';

const initialState: TransactionsState = {
  ethTransactions: [],
  erc20Transactions: [],
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addEthTransactions(state, action: PayloadAction<TransactionDepositData[]>) {
      state.ethTransactions.push(...action.payload);
    },
    addErc20Transactions(
      state,
      action: PayloadAction<TransactionDepositData[]>,
    ) {
      state.erc20Transactions.push(...action.payload);
    },
    clearTransactions(state) {
      state.ethTransactions = [];
      state.erc20Transactions = [];
    },
  },
});

export const { addEthTransactions, addErc20Transactions, clearTransactions } =
  transactionsSlice.actions;

export default transactionsSlice.reducer;
