import React, { FC, useMemo } from 'react';

import { t } from 'i18next';

import { Link, Footer as UIFooter } from '@sovryn/ui';

import { SocialLinks } from '../../molecules/SocialLinks/SocialLinks';
import { SovrynLogo } from '../../molecules/SovrynLogo/SovrynLogo';
import Logo from '../../../assets/images/Bitcoin-logo-small.svg';
import { WEBSITE_LINKS } from '../../../constants/links';
import { translations } from '../../../locales/i18n';

type FooterProps = {
  showDashboardLink?: boolean;
};

export const Footer: FC<FooterProps> = ({ showDashboardLink }) => {
  const footerLinks = useMemo(
    () => [
      {
        id: 'website',
        href: WEBSITE_LINKS.ROOT,
        name: t(translations.footer.website),
      },
    ],
    [],
  );

  return (
    <UIFooter
      leftContent={
        <SovrynLogo
          image={Logo}
          dataAttribute="footer-logo"
          className="max-h-4 max-w-fit mr-2"
          text="Powered by bitcoin"
          link="/"
        />
      }
      links={
        <div className="flex flex-row justify-center flex-wrap gap-x-6 gap-y-5">
          {footerLinks.map(link => (
            <Link key={link.id} href={link.href} text={link.name} />
          ))}
        </div>
      }
      rightContent={<SocialLinks dataAttribute="footer-social" />}
    />
  );
};
