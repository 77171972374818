import { constants } from 'ethers';
import { Environments } from '../../types/global';

export const ETH_TOKENS: Record<string, Record<Environments, string>> = {
  wbtc: {
    [Environments.Mainnet]: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    [Environments.Testnet]: '0x6404DD00Ff4d92B0bfD9A7EA032E592f47384F6D',
  },
  tbtc: {
    [Environments.Mainnet]: '0x18084fba666a33d37592fa2633fd49a74dd93a88',
    [Environments.Testnet]: '0x8dAEBADE922dF735c38C80C7eBD708Af50815fAa', //TODO update testnet address
  },
  eth: {
    [Environments.Mainnet]: constants.AddressZero,
    [Environments.Testnet]: constants.AddressZero,
  },
  reth: {
    [Environments.Mainnet]: '0xae78736cd615f374d3085123a210448e74fc6393',
    [Environments.Testnet]: '0x178E141a0E3b34152f73Ff610437A7bf9B83267A',
  },
  wsteth: {
    [Environments.Mainnet]: '0x7f39c581f595b53c5cb19bd0b3f8da6c935e2ca0',
    [Environments.Testnet]: '0x6e4f1e8d4c5e5e6e2781fd814ee0744cc16eb352',
  },
  usdt: {
    [Environments.Mainnet]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    [Environments.Testnet]: '0x395131c2360101acB9Fa8a4d412b0bc43607DF22',
  },
  usdc: {
    [Environments.Mainnet]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    [Environments.Testnet]: '0x0F004Fd9e9e1f884975908137F5494C3cA1D9914',
  },
  dai: {
    [Environments.Mainnet]: '0x6b175474e89094c44da98b954eedeac495271d0f',
    [Environments.Testnet]: '0x39F696fC50a39E26c93c903eeF1fff1Df5c392C4',
  },
};
