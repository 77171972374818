import React, { FC, useMemo } from 'react';
import { SupportedEthTokens } from '../../../types/types';
import { AssetRenderer } from '../AssetRenderer/AssetRenderer';

export const DepositTokens: FC = () => {
  const tokenOptions = useMemo(
    () =>
      Object.values(SupportedEthTokens).map(token => (
        <span title={token?.toUpperCase()} key={token}>
          <AssetRenderer
            showAssetLogo
            assetClassName="text-[0]"
            asset={token}
          />
        </span>
      )),
    [],
  );

  return <div className="flex items-center mt-2">{tokenOptions}</div>;
};
