import { useEffect, useState } from 'react';
import { useGetProtocolContract } from '../../../../hooks/useGetContract';

export const useGetLockDropStatus = () => {
  const lockDropContract = useGetProtocolContract('lockDrop');

  const [lockDropContractStatus, setLockDropContractStatus] = useState({
    isPaused: true,
    isWithdrawalTimeStarted: true,
  });

  useEffect(() => {
    const fetchStatus = async () => {
      if (!lockDropContract) {
        return;
      }

      try {
        const isPaused = await lockDropContract.paused();
        const isWithdrawalTimeStarted =
          await lockDropContract.isWithdrawalTimeStarted();

        setLockDropContractStatus({
          isPaused: isPaused,
          isWithdrawalTimeStarted: isWithdrawalTimeStarted,
        });
      } catch (error) {
        console.error('Error fetching contract status:', error);
        setLockDropContractStatus({
          isPaused: true,
          isWithdrawalTimeStarted: true,
        });
      }
    };

    fetchStatus();
  }, [lockDropContract]);

  return { lockDropContractStatus };
};
