import React, { FC, useCallback, useReducer } from 'react';

import {
  Button,
  ButtonStyle,
  Header as UIHeader,
  Icon,
  IconNames,
} from '@sovryn/ui';
import { useWalletConnect } from '../../../hooks/useWalletConnect';
import { useWrongNetworkCheck } from '../../../hooks/useWrongNetworkCheck';
import { SovrynLogo } from '../../molecules/SovrynLogo/SovrynLogo';
import { ConnectWalletButton } from '../../molecules/ConnectWalletButton/ConnectWalletButton';
import { WEBSITE_LINKS } from '../../../constants/links';
import { menuItemsMapping } from './Header.constants';
import { NavItem } from './components/NavItem/NavItem';

export const Header: FC = () => {
  const [isOpen, toggle] = useReducer(v => !v, false);
  const { connectWallet, disconnectWallet, account, pending } =
    useWalletConnect();
  useWrongNetworkCheck();

  const handleNavClick = useCallback(() => {
    if (isOpen) {
      toggle();
    }
  }, [isOpen]);

  return (
    <>
      <UIHeader
        dataAttribute="dapp-header"
        logo={
          <SovrynLogo
            dataAttribute="header-logo"
            link={WEBSITE_LINKS.DAPP}
            onClick={handleNavClick}
          />
        }
        isOpen={isOpen}
        menuIcon={
          <Button
            text={
              <Icon
                icon={isOpen ? IconNames.X_MARK : IconNames.HAMBURGER_MENU}
              />
            }
            style={ButtonStyle.ghost}
            onClick={toggle}
            className="text-white"
          />
        }
        menuItems={
          <ol className="flex flex-col gap-4 lg:flex-row w-full lg:w-auto">
            {menuItemsMapping.map((item, index) => (
              <li key={index}>
                <NavItem item={item} onClick={toggle} />
              </li>
            ))}
          </ol>
        }
        secondaryContent={
          <div className="relative flex flex-row gap-4">
            <ConnectWalletButton
              onConnect={connectWallet}
              onDisconnect={disconnectWallet}
              address={account}
              pending={pending}
              dataAttribute="dapp-header-connect"
            />
          </div>
        }
      />
    </>
  );
};
